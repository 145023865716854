import { formatDate } from '@angular/common';

import { EnumEtapeCleStatut, EnumEtapeCleType, EnumRoleStructure, ProjetEtapeCle } from '@shared-ui';

function inferEtapeCleIndex(etapeCle: ProjetEtapeCle, etapesCle: readonly ProjetEtapeCle[]): number {
  const filteredSortedEtapesCle = etapesCle
    .filter(ec => ec.type === etapeCle.type)
    .sort((ec1, ec2) => new Date(ec1.dateVersementPrev).getTime() - new Date(ec2.dateVersementPrev).getTime());

  return filteredSortedEtapesCle.findIndex(ec => ec.id === etapeCle.id) + 1;
}

function isAvanceInitiale(etapeCle: ProjetEtapeCle): boolean {
  return [EnumEtapeCleType.AVANCE_INITIALE, EnumEtapeCleType.AVANCE_INITIALE_SANS_CP].includes(etapeCle?.type);
}

function buildEtapeCleTitle(etapeCle: ProjetEtapeCle, etapesCle: readonly ProjetEtapeCle[], dateFormat: string): string | undefined {
  if (!etapeCle) return undefined;

  const date = formatDate(new Date(etapeCle.dateVersementReel || etapeCle.dateVersementPrev), dateFormat, 'fr-FR');

  switch (etapeCle.type) {
    case EnumEtapeCleType.AVANCE_INITIALE:
    case EnumEtapeCleType.AVANCE_INITIALE_SANS_CP:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.AVANCE_INITIALE)} ${date}`;
    case EnumEtapeCleType.SOLDE:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.SOLDE)} ${date}`;
    default:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.ETAPE_CLE)} ${inferEtapeCleIndex(etapeCle, etapesCle)} ${date}`;
  }
}

function isEtapeCleEncours(etapeCle: ProjetEtapeCle) {
  return etapeCle?.statut === EnumEtapeCleStatut.EN_COURS;
}

function isEtapeCleNonValidee(etapeCle: ProjetEtapeCle) {
  return etapeCle?.statut !== EnumEtapeCleStatut.VALIDEE;
}

function sortStructuresByRole<T extends { role: string }>(structures: T[]) {
  return structures.sort((s1, s2) => {
    if (s1.role === EnumRoleStructure.CHEF_DE_FILE && s2.role !== EnumRoleStructure.CHEF_DE_FILE) {
      return -1;
    } else if (s1.role !== EnumRoleStructure.CHEF_DE_FILE && s2.role === EnumRoleStructure.CHEF_DE_FILE) {
      return 1;
    }
    return 0;
  });
}

export { inferEtapeCleIndex, buildEtapeCleTitle, isAvanceInitiale, isEtapeCleEncours, isEtapeCleNonValidee, sortStructuresByRole };
